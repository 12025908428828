import { redirect } from 'react-router';
import type { Route } from './+types/_index';


export const loader = async ( { request } : Route.LoaderArgs ) => {
  return redirect( '/dashboard' );
};

export default function Index() {
  return null;
}
